'use strict'

function unifyLinkTargetWindows() {
    // make sure all external links open in a new browser tab
    window
        .$('a[href^="http://"], a[href^="https://"], a[href^="//"]')
        .each(function(index, element) {
            let $element = window.$(element)
            let domain = window.location.hostname

            // don't set _blank target for own domain
            if (!$element.attr('href').includes(domain)) {
                $element.attr('target', '_blank')
            }
        })
}
export { unifyLinkTargetWindows }
