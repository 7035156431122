import axios from 'axios'
import { django_i18n } from './django_i18n'

export const axios_instance = axios.create()

axios_instance.defaults.xsrfCookieName = 'csrftoken'
axios_instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const _ = django_i18n.gettext

function text_error(text) {
    return { __all__: [text] }
}

function error_response(response) {
    if (response.headers['content-type'] === 'application/json') {
        return response.data
    } else {
        return text_error(
            _(
                'Ein Fehler ist aufgetreten. Bitte laden Sie die Seite erneut und versuchen Sie es noch einmal.'
            )
        )
    }
}

export function get_basket(success, error) {
    return axios_instance
        .get('/api/v1/basket/my_basket/')
        .then(response => {
            let basket = response.data
            success(basket)
        })
        .catch(function() {
            error(text_error(_('Der Warenkorb konnte nicht geladen werden.')))
        })
}

export function get_basket_options(success, error) {
    return axios_instance
        .options('/api/v1/basket/my_basket/', {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            let basket_options = response.data
            success(basket_options)
        })
        .catch(function() {
            error(
                text_error(
                    _('Die Warenkorb-Optionen konnten nicht geladen werden.')
                )
            )
        })
}

export function update_basket(data, success, error) {
    return axios_instance
        .patch('/api/v1/basket/my_basket/', data)
        .then(response => {
            const basket = response.data
            success(basket)
        })
        .catch(e => {
            error(error_response(e.response))
        })
}

export function update_line(line, data, success, error) {
    return axios_instance
        .patch(line.url, data)
        .then(response => {
            let line = response.data
            success(line)
        })
        .catch(e => {
            error(error_response(e.response))
        })
}

export function delete_line(line, success, error) {
    return axios_instance
        .delete(line.url)
        .then(() => success())
        .catch(e => {
            error(error_response(e.response))
        })
}

export function add_product(product, quantity, success, error) {
    return axios_instance
        .post('/api/v1/basket_lines/', {
            product_id: product.id,
            quantity: quantity,
        })
        .then(response => {
            let line = response.data
            success(line)
        })
        .catch(e => {
            error(error_response(e.response))
        })
}

export function get_calendar(success, error) {
    return axios_instance
        .get('/api/v1/calendar/')
        .then(response => {
            let calendar = response.data
            success(calendar)
        })
        .catch(e => {
            error(error_response(e.response))
        })
}

export function find_stockrecords(query, success, error) {
    return axios_instance
        .get('/api/v1/stockrecords/', {
            params: { search: query },
        })
        .then(response => {
            let stockrecords = response.data
            success(stockrecords)
        })
        .catch(e => {
            error(error_response(e.response))
        })
}
