<template>
    <div class="wdm-add_to_basket">
        <div v-bind:class="{ hidden: !is_closed }">
            <div class="form-group">
                <button
                    class="btn btn-link"
                    type="button"
                    v-on:click.prevent="open()"
                >
                    <i class="fa fa-2x fa-shopping-cart"></i>
                </button>
            </div>
        </div>
        <div v-bind:class="{ hidden: !is_open }">
            <div class="form-group">
                <div class="input-group">
                    <input
                        name="quantity"
                        type="number"
                        class="form-control"
                        placeholder="Anzahl"
                        min="1"
                        tabindex="-1"
                        v-model="quantity"
                    />
                    <button
                        class="btn btn-primary"
                        type="submit"
                        v-on:click="submit()"
                        :disabled="!is_valid"
                    >
                        <i class="fa fa-2x fa-shopping-cart"></i>
                    </button>

                    <button
                        class="btn btn-link btn-close"
                        type="button"
                        v-on:click.prevent="close()"
                    >
                        <i class="fa fa-2x fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
        <div v-bind:class="{ hidden: !is_submitting }">
            <div class="form-group">
                <i class="fa fa-2x fa-spin fa-spinner"></i>
            </div>
        </div>
    </div>
</template>

<script>
const STATE_OPEN = 'open'
const STATE_CLOSED = 'closed'
const STATE_SUBMITTING = 'submitting'

export default {
    data() {
        return {
            state: STATE_CLOSED,
            quantity: null,
        }
    },
    props: {},
    components: {},
    methods: {
        open: function () {
            this.state = STATE_OPEN
        },
        close: function () {
            this.state = STATE_CLOSED
        },
        submit: function () {
            this.state = STATE_SUBMITTING
        },
    },
    computed: {
        is_open: function () {
            return this.state === STATE_OPEN
        },
        is_closed: function () {
            return this.state === STATE_CLOSED
        },
        is_submitting: function () {
            return this.state === STATE_SUBMITTING
        },
        is_valid: function () {
            return this.quantity !== null && this.quantity > 0
        },
    },
}
</script>

<style></style>
