import jQuery from 'jquery'

let $ = jQuery

$(document).on('submit', 'form', function (event) {
    let button = $(this).find('[data-loading-text]')
    if (button.length > 0) {
        let isSubmitting = button.data('isSubmitting')
        if (isSubmitting) {
            event.preventDefault()
            return
        }
        button.data('isSubmitting', true)

        let $this = button
        let loadingText = $this.data('loadingText')
        let originalText = $this.text()
        $this.text(loadingText)
        window.setTimeout(function () {
            // we can't disable immediately as that will prevent the form from submitting
            $this.attr('disabled', true)
        }, 0)
        window.setTimeout(function () {
            $this.text(originalText)
            $this.attr('disabled', false)
            button.data('isSubmitting', false)
        }, 10000) // if it doesn't work in 10 seconds, let the user try again
    }
})
