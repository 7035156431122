import * as api from '../../api'
import * as types from '../mutation-types'

// initial state
const state = {
    objects: [],
    errors: {},
    last_query: null,
}

const getters = {}

const actions = {
    find_stockrecords({ commit, state }, { query }) {
        commit(types.STOCKRECORDS_QUERY_START, { query })
        api.find_stockrecords(
            query,
            stockrecords => {
                if (state.last_query === query) {
                    // only update if we where the last query
                    commit(types.STOCKRECORDS_CHANGE, { stockrecords })
                }
                commit(types.STOCKRECORDS_QUERY_END, { query })
            },
            errors => {
                if (state.last_query === query) {
                    // only show error if we where the last query
                    commit(types.STOCKRECORDS_ERRORS, { errors })
                }
                commit(types.STOCKRECORDS_QUERY_END, { query })
            }
        )
    },
}

// mutations
const mutations = {
    [types.STOCKRECORDS_QUERY_START](state, { query }) {
        state.last_query = query
    },
    [types.STOCKRECORDS_QUERY_END](state, { query }) {
        if (state.last_query === query) {
            state.last_query = null
        }
    },
    [types.STOCKRECORDS_CHANGE](state, { stockrecords }) {
        state.objects = stockrecords
    },
    [types.STOCKRECORDS_DELETE](state) {
        state.objects = []
        state.last_query = null
    },
    [types.STOCKRECORDS_ERRORS](state, { errors }) {
        state.errors = errors
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
