<template>
    <div>
        <div v-bind:class="{ hidden: newAddress }">
            <slot name="existing"></slot>
            <span class="btn btn-info pull-right" v-on:click="toggleAddress">{{
                gettext(newAddressText)
            }}</span>
        </div>
        <div v-bind:class="{ hidden: !newAddress }">
            <slot name="new"></slot>
            <span class="btn btn-link pull-right" v-on:click="toggleAddress">{{
                gettext('Bestehende Adresse wählen')
            }}</span>
        </div>
        <input
            style="display: none"
            type="checkbox"
            :name="newAddressField"
            v-model="newAddress"
        />
    </div>
</template>

<style></style>

<script>
export default {
    data() {
        return {
            newAddress: this.initialNewAddress,
        }
    },
    props: {
        initialNewAddress: Boolean,
        newAddressField: String,
        newAddressText: {
            type: String,
            default: 'Neue Adresse anlegen',
        },
    },
    components: {},
    methods: {
        toggleAddress: function () {
            this.newAddress = !this.newAddress
        },
    },
    computed: {},
}
</script>
