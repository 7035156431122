import $ from 'jquery'
import { django_i18n } from './django_i18n'
import 'bootstrap/js/modal'

$(document).ready(function() {
    $('#add-to-basket-modal').on('show.bs.modal', function(event) {
        const $button = $(event.relatedTarget)
        const productId = $button.data('product-id')
        const $modal = $(this)
        $modal
            .find('.modal-content')
            .html(
                '<div class="align-center"><i class="fa fa-spinner fa-spin fa-4x"></i></div>'
            )
        $.get('/basket/add_popup/' + productId + '/').then(function(response) {
            $modal
                .find('.modal-content')
                .html(response)
                .find('button[name="add"]')
                .after(
                    $(
                        '<button class="btn btn-default pull-right" data-dismiss="modal">' +
                            django_i18n.gettext('Abbrechen') +
                            '</button>'
                    )
                )

            window.setTimeout(function() {
                $modal.find('.quantity:first').focus()
            }, 500) // for some reason it doesn't work without settimeout
        })
    })
})
