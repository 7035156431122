<template>
    <div class="row spaceless tr">
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 td">
            <!--{% thumbnail image.original "200x200" upscale=False as thumb %}-->
            <a :href="product.catalogue_url">
                <img
                    class="thumbnail img-responsive image-left"
                    :src="product.image"
                    :alt="product.title"
                />
            </a>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10 td">
            <a class="text-bold" :href="line.product.catalogue_url"
                >{{ gettext('Produktseite von') }} {{ product.title }}</a
            >
            <p v-html="product.description"></p>

            <table class="table-dl">
                <tbody>
                    <tr
                        v-for="(
                            entry, index
                        ) in product.form_of_delivery_details"
                        :key="entry.name + index"
                    >
                        <!-- FIXME i18n -->
                        <th v-if="entry.name !== 'Container'">
                            {{ entry.name }}
                        </th>
                        <!-- FIXME #65 -->
                        <th v-if="entry.name === 'Container'">Pallet</th>
                        <td>
                            {{ booleanYesNo(entry.value) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    props: ['line'],
    data() {
        return {}
    },
    computed: {
        form_of_delivery_details() {
            // FIXME
            return this.line.product.form_of_delivery_details.filter(
                (entry) => entry.name !== 'VE' && entry.name !== 'Gebinde'
            )
        },
        product() {
            return this.line.product
        },
    },
    methods: {
        booleanYesNo: function (value) {
            if (typeof value === 'boolean') {
                return value ? this.gettext('Ja') : this.gettext('Nein')
            } else {
                return value
            }
        },
    },
}
</script>

<style scoped></style>
