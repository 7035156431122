import { createStore as baseCreateStore } from 'vuex'

import basket from './modules/basket'
import stockrecords from './modules/stockrecords'

export const createStore = () =>
    baseCreateStore({
        modules: {
            basket,
            stockrecords,
        },
        strict: false,
    })

export const store = createStore()
