<template>
    <div class="vue-dropdown">
        <span v-if="!open">
            <a
                href="#"
                class="btn btn-additional-info inline-form-element change_quantity_button"
                v-on:click.prevent="startEdit()"
                :title="gettext('Menge ändern')"
            >
                {{ gettext('ändern') }} <i class="fa fa-caret-down"></i>
            </a>
        </span>
        <div v-if="open">
            <div class="vue-backdrop" v-on:click.prevent="cancelEdit()"></div>
            <a
                href="#"
                class="btn btn-additional-info inline-form-element active"
                v-on:click.prevent="cancelEdit()"
            >
                {{ gettext('ändern') }} <i class="fa fa-caret-down"></i>
            </a>
            <div class="vue-dropdown-pane">
                <div v-if="line.errors && line.errors.quantity">
                    <div
                        v-for="error in line.errors.quantity"
                        class="alert alert-danger"
                        role="alert"
                        :key="error"
                    >
                        {{ error }}
                    </div>
                </div>

                <table class="table table-condensed table-form paragraph">
                    <tbody>
                        <tr v-if="showBundleUnit">
                            <td class="table-form-element">
                                <input
                                    name="bundle-quantity"
                                    class="form-control"
                                    v-model.number="bundle_unit_quantity"
                                    type="number"
                                    v-on:keyup.enter="save()"
                                />
                            </td>
                            <td class="table-form-addon">
                                <div class="inline-block">
                                    {{ bundleUnitName }}
                                </div>
                                <div class="inline-block">
                                    (je {{ trade_units_per_bundle_unit }}
                                    {{ tradeUnitName }})
                                </div>
                            </td>
                        </tr>
                        <tr v-if="showPackagingUnit">
                            <td class="table-form-element">
                                <input
                                    name="packaging-quantity"
                                    class="form-control"
                                    v-model.number="packaging_unit_quantity"
                                    type="number"
                                    v-on:keyup.enter="save()"
                                />
                            </td>
                            <td class="table-form-addon">
                                <div class="inline-block">
                                    {{ packagingUnitName }}
                                </div>
                                <div class="inline-block">
                                    (je {{ trade_units_per_packaging_unit }}
                                    {{ tradeUnitName }})
                                </div>
                            </td>
                        </tr>
                        <tr v-if="showTradeUnit">
                            <td class="table-form-element">
                                <input
                                    name="trade-quantity"
                                    class="form-control"
                                    v-model.number="trade_unit_quantity"
                                    type="number"
                                    v-on:keyup.enter="save()"
                                />
                            </td>
                            <td class="table-form-addon">
                                {{ tradeUnitName }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button
                    class="btn btn-primary pull-right-margin"
                    type="submit"
                    v-on:click="save()"
                >
                    <i class="fa fa-check"></i> {{ gettext('Speichern') }}
                </button>
                <button
                    class="btn btn-danger pull-right"
                    type="submit"
                    v-on:click="remove()"
                >
                    {{ gettext('Entfernen') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import * as types from '../store/mutation-types'

// remove this to re-enable editing of units
const DISABLE_UNIT_EDITING = true

export default {
    props: ['line'],
    data() {
        return {
            trade_unit_quantity: this.line.quantities.trade,
            packaging_unit_quantity: this.line.quantities.packaging,
            bundle_unit_quantity: this.line.quantities.bundle,
            editMode: false,
        }
    },
    methods: {
        startEdit: function () {
            this.editMode = true
        },
        cancelEdit: function () {
            this.editMode = false
            this.trade_unit_quantity = this.line.quantities.trade
            this.packaging_unit_quantity = this.line.quantities.packaging
            this.bundle_unit_quantity = this.line.quantities.bundle
            let errors = {}
            Object.assign(errors, this.line.errors)
            Object.assign(errors, { quantity: [] })
            this.$store.commit(types.LINE_ERRORS, { line: this.line, errors })
        },
        save: function () {
            if (this.quantity === 0) {
                this.remove()
            } else {
                this.$store.dispatch('update_line', {
                    line: this.line,
                    data: {
                        quantity: this.quantity,
                    },
                })
                this.editMode = false
            }
        },
        remove: function () {
            this.$store.dispatch('delete_line', { line: this.line })
            this.editMode = false
        },
    },
    computed: {
        open: function () {
            return (
                this.editMode ||
                (this.line.errors &&
                    this.line.errors.quantity &&
                    this.line.errors.quantity.length > 0)
            )
        },
        tradeUnitName: function () {
            return this.line.product.trade_unit
                ? this.line.product.trade_unit.name_plural
                : ''
        },
        packagingUnitName: function () {
            return this.line.product.packaging_unit
                ? this.line.product.packaging_unit.name_plural
                : ''
        },
        bundleUnitName: function () {
            return this.line.product.bundle_unit
                ? this.line.product.bundle_unit.name_plural
                : ''
        },
        trade_units_per_packaging_unit: function () {
            return this.line.product.trade_units_per_packaging_unit
        },
        trade_units_per_bundle_unit: function () {
            return this.line.product.trade_units_per_bundle_unit
        },
        showTradeUnit: function () {
            if (DISABLE_UNIT_EDITING) {
                return true
            }
            return this.trade_units_per_packaging_unit !== 1
        },
        showPackagingUnit: function () {
            if (DISABLE_UNIT_EDITING) {
                return false
            }
            return (
                this.trade_units_per_bundle_unit !==
                    this.trade_units_per_packaging_unit && this.hasPackagingUnit
            )
        },
        showBundleUnit: function () {
            if (DISABLE_UNIT_EDITING) {
                return false
            }
            return this.hasBundleUnit
        },
        hasPackagingUnit: function () {
            return (
                this.trade_units_per_packaging_unit !== undefined &&
                this.trade_units_per_packaging_unit !== 0
            )
        },
        hasBundleUnit: function () {
            return (
                this.trade_units_per_bundle_unit !== undefined &&
                this.trade_units_per_bundle_unit !== 0
            )
        },
        quantity: function () {
            return (
                this.trade_unit_quantity +
                this.packaging_unit_quantity *
                    this.trade_units_per_packaging_unit +
                this.bundle_unit_quantity * this.trade_units_per_bundle_unit
            )
        },
    },
}
</script>

<style scoped></style>
