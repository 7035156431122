/* global _paq */

export function sendBasketToPiwik(basket) {
    for (const line of basket.lines) {
        _paq.push([
            'addEcommerceItem',
            line.stock_record.partner_sku,
            line.product.title,
            line.product.categories.map(({ name }) => name),
            line.unit_price_excl_tax,
            line.quantity,
        ])
    }
    _paq.push(['trackEcommerceCartUpdate', basket.total_excl_tax])
}
