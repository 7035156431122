export function parse_date(date_string) {
    if (date_string === null || date_string === undefined) {
        return null
    }
    const split = date_string.split('-')
    const year = parseInt(split[0], 10)
    const month = parseInt(split[1], 10) - 1 // months are zero-based, rest 1-based
    const day = parseInt(split[2], 10)

    return new Date(year, month, day)
}
