import django_de from '../../static/jsi18n/de/djangojs'
import django_en from '../../static/jsi18n/en/djangojs'
import django_fr from '../../static/jsi18n/fr/djangojs'

// get language from html tag
const lang = document.documentElement.getAttribute('lang')
// set django to corresponding language import
export let django_i18n = django_de
if (lang === 'en') {
    django_i18n = django_en
} else if (lang === 'fr') {
    django_i18n = django_fr
}

// repetition of all translations, that appear in .vue templates
// in order to be found be django's translation manage commands
django_i18n.gettext('Art.-Nr.')
django_i18n.gettext('Produkte')
django_i18n.gettext(
    'Ein Produkt in ihrem Warenkorb hat ein zu frühes oder nicht gesetztes Lieferdatum.'
)
django_i18n.gettext(
    'Produkte in ihrem Warenkorb haben ein zu frühes oder nicht gesetztes Lieferdatum.'
)
django_i18n.gettext('Aktualisieren')
django_i18n.gettext('Lieferung')
django_i18n.gettext('Menge')
django_i18n.gettext('Menge ändern')
django_i18n.gettext('Preis')
django_i18n.gettext('Rabatt')
django_i18n.gettext('Gesamt')
django_i18n.gettext('Produktmuster und Infomaterial')
django_i18n.gettext('Jetzt kostenlos bestellen')
django_i18n.gettext('Weiter zur Kasse')
django_i18n.gettext('Gesamtbeträge')
django_i18n.gettext('Versand')
django_i18n.gettext('Nettosumme')
django_i18n.gettext('Umsatzsteuer')
django_i18n.gettext('Summe')
django_i18n.gettext('Informationen zum Produkt anzeigen')
django_i18n.gettext('Lieferdatum')
django_i18n.gettext('gratis')
django_i18n.gettext('Liefertermin wählen')
django_i18n.gettext('Frühester Liefertermin')
django_i18n.gettext('Produktseite von')
django_i18n.gettext('Ja')
django_i18n.gettext('Nein')
django_i18n.gettext('ändern')
django_i18n.gettext('Speichern')
django_i18n.gettext('Entfernen')
django_i18n.gettext('Artikelnummer, Name oder GTIN eingeben')
django_i18n.gettext('GTIN')
django_i18n.gettext('Produkt nicht gefunden')
django_i18n.gettext('Artikel')
django_i18n.gettext('Anzahl')
django_i18n.gettext('In den Warenkorb')
django_i18n.gettext('Datum wählen')
django_i18n.gettext('Bestehende Adresse wählen')
django_i18n.gettext('Neue Adresse anlegen')
django_i18n.gettext('Bestelllisten')
