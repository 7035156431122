<template>
    <div class="vue-dropdown open-left discount-breakdown">
        <span v-if="!hasDetails" class="text-body">
            {{ localize_float(breakdown.percentage) }} %
        </span>
        <div v-else-if="!open">
            <a
                href="#"
                class="btn btn-additional-info inline-form-element"
                v-on:click.prevent="onOpen()"
            >
                <span class="text-body">
                    {{ localize_float(breakdown.percentage) }} %
                </span>
                <i class="fa fa-caret-down"></i>
            </a>
        </div>
        <div v-else-if="open">
            <div class="vue-backdrop" v-on:click.prevent="onClose()"></div>
            <a
                href="#"
                class="btn btn-additional-info inline-form-element active"
                v-on:click.prevent="onClose()"
            >
                <span class="text-body">
                    {{ localize_float(breakdown.percentage) }} %
                </span>
                <i class="fa fa-caret-down"></i>
            </a>
            <div class="vue-dropdown-pane">
                <table class="table table-condensed paragraph">
                    <tbody>
                        <tr
                            v-for="(detail, index) in this.breakdown.details"
                            :key="index"
                        >
                            <td class="text-left discount-name">
                                {{ detail.name }}
                            </td>
                            <td>{{ detail.percentage }}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { localize_float } from '../format'

export default {
    name: 'discount_breakdown',
    props: ['breakdown'],
    data() {
        return {
            open: false,
        }
    },
    computed: {
        hasDetails: function() {
            return (
                this.breakdown.details !== undefined &&
                this.breakdown.details.length > 0
            )
        },
    },
    methods: {
        localize_float,
        onOpen: function() {
            this.open = true
        },
        onClose: function() {
            this.open = false
        },
    },
}
</script>

<style scoped></style>
