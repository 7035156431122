import jQuery from 'jquery'
import 'slick-carousel'

function carousel() {
    jQuery('.carousel').each(function(index, elem) {
        let $elem = jQuery(elem)
        // exit condition
        if ($elem.data('initialized') != undefined) {
            return
        }
        $elem.data('initialized', true)

        let gridColumns = $elem.data('grid-columns')
        if (!gridColumns) gridColumns = 4
        let size = Math.min($elem.data('size'), gridColumns)
        let slidesToShow = $elem.data('blocks-to-show')
        if (!slidesToShow) slidesToShow = size

        // screenSizes
        const xxs = 480
        const xs = 768
        const sm = 992
        const md = 1200

        // define maximum blocksToShow per screenSize
        let maxBlocksResponsive = []
        maxBlocksResponsive.push([xxs, 1])

        if (slidesToShow > 1) {
            if (size == 3) {
                maxBlocksResponsive.push([xs, 1])
            } else {
                // size == 4
                maxBlocksResponsive.push([sm, 1])
            }
        }
        if (slidesToShow > 2) {
            if (size == 3) {
                maxBlocksResponsive.push([md, 2])
            } else {
                // size == 4
                maxBlocksResponsive.push([sm, 2])
                maxBlocksResponsive.push([md, 3])
            }
        }

        // convert breaks into appropriate slick responsive options dict
        let responsive = []
        maxBlocksResponsive.forEach(function(item) {
            // console.log(gridColumns);
            responsive.push({
                breakpoint: item[0],
                settings: {
                    slidesToShow: item[1],
                },
            })
        })

        // initialize slick
        jQuery(elem).slick({
            infinite: true,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            responsive: responsive,
        })
    })
}

export { carousel }
