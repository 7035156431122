<template>
    <div class="quick_add form-inline grid-item">
        <div class="form-group">
            <div class="input-group">
                <multiselect
                    ref="multiselect"
                    v-model="selectedStockRecord"
                    id="quick_add_product"
                    label="partner_sku"
                    trackBy="partner_sku"
                    :placeholder="
                        gettext('Artikelnummer, Name oder GTIN eingeben')
                    "
                    selectLabel=""
                    deselectLabel=""
                    :customLabel="productLabel"
                    :options="stockRecords"
                    :allow-empty="false"
                    :loading="isLoading"
                    :searchable="true"
                    :show-no-options="false"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="10"
                    @select="onStockRecordSelect"
                    @search-change="asyncFind"
                >
                    <!--                  <template v-slot:noOptions><span>...</span></template>-->
                    <template v-slot:noResult
                        ><span>{{
                            gettext('Produkt nicht gefunden')
                        }}</span></template
                    >
                </multiselect>
                <span class="input-group-addon">{{ gettext('Artikel') }}</span>
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <input
                    ref="quantity"
                    name="quick-add-quantity"
                    class="form-control quick-add-quantity"
                    v-model.number="quantity"
                    @keyup.enter="addToBasket"
                    type="number"
                />
                <span class="input-group-addon">{{ gettext('Anzahl') }}</span>
            </div>
        </div>

        <div class="form-group">
            <select
                name="quick-add-unit"
                class="form-control"
                v-model="selectedUnit"
                v-if="unitsAreKnown"
                @keyup.enter="addToBasket"
            >
                <option
                    v-for="option in units"
                    v-bind:value="option.value"
                    v-bind:key="option.value"
                >
                    {{ option.label }}
                </option>
            </select>
        </div>

        <div class="form-group" v-if="unitsAreKnown">
            <input
                type="submit"
                :value="gettext('In den Warenkorb')"
                class="btn btn-success"
                v-on:click="addToBasket"
            />
        </div>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<script>
import Multiselect from 'vue-multiselect'
import * as types from '../store/mutation-types'

export default {
    data() {
        return {
            selectedUnit: null,
            selectedStockRecord: null,
            quantity: null,
        }
    },
    components: {
        Multiselect,
    },
    methods: {
        productLabel({ partner_sku, product }) {
            return `${partner_sku} ${product.title} (${this.gettext('GTIN')} ${
                product.upc
            })`
        },
        focusSearch() {
            this.$refs.multiselect.$refs.search.focus()
        },
        onStockRecordSelect(stockRecord) {
            // default to packaging unit if available, otherwise pick first unit
            let product = stockRecord.product
            if (
                product.trade_units_per_packaging_unit !== 0 &&
                product.packaging_unit
            ) {
                this.selectedUnit = 'packaging'
            } else {
                this.selectedUnit = this.stockRecordUnits(stockRecord)[0].value
            }
            this.$refs.quantity.focus()
        },
        asyncFind(query) {
            this.$store.dispatch('find_stockrecords', { query })
        },
        stockRecordUnits(stockRecord) {
            let product = stockRecord.product
            let units = []
            if (
                product.trade_units_per_packaging_unit !== 1 &&
                product.trade_unit
            ) {
                units.push({
                    value: 'trade',
                    label: product.trade_unit.name_plural,
                })
            }
            if (
                product.trade_units_per_packaging_unit !== 0 &&
                product.packaging_unit
            ) {
                units.push({
                    value: 'packaging',
                    label: product.packaging_unit.name_plural,
                })
            }
            if (
                product.trade_units_per_bundle_unit !== 0 &&
                product.bundle_unit
            ) {
                units.push({
                    value: 'bundle',
                    label: product.bundle_unit.name_plural,
                })
            }
            return units
        },
        addToBasket() {
            let quantity = this.quantityForUnit
            if (!quantity > 0 || this.selectedStockRecord === null) {
                return
            }
            this.$store.dispatch('add_product', {
                product: this.selectedStockRecord.product,
                quantity,
            })
            // clear stock records
            this.$store.commit(types.STOCKRECORDS_DELETE)
            this.selectedUnit = null
            this.selectedStockRecord = null
            this.quantity = null
            this.focusSearch()
        },
    },
    computed: {
        quantityForUnit: function () {
            if (
                this.selectedStockRecord === null ||
                this.selectedUnit === null
            ) {
                return this.quantity
            }
            let product = this.selectedStockRecord.product

            if (this.selectedUnit === 'bundle') {
                return product.trade_units_per_bundle_unit * this.quantity
            }
            if (this.selectedUnit === 'packaging') {
                return product.trade_units_per_packaging_unit * this.quantity
            }
            return this.quantity
        },
        units: function () {
            if (this.selectedStockRecord === null) {
                return []
            }
            return this.stockRecordUnits(this.selectedStockRecord)
        },
        unitsAreKnown: function () {
            return this.selectedStockRecord !== null
        },
        stockRecords: function () {
            return this.$store.state.stockrecords.objects
        },
        isLoading: function () {
            return this.$store.state.stockrecords.last_query !== null
        },
    },
}
</script>
