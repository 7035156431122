<template>
    <div class="shipping_method">
        <div v-if="basket_options.errors">
            <div
                v-for="error in basket_options.errors"
                class="alert alert-danger"
                role="alert"
                :key="error"
            >
                {{ error }}
            </div>
        </div>
        <div v-else-if="shipping_code !== undefined && show_shipping">
            <h3>{{ gettext('Versand') }}</h3>
            <div
                v-for="shipping_method in shipping_methods"
                :key="shipping_method.value"
            >
                <input
                    type="radio"
                    :checked="shipping_method.value === shipping_code"
                    :id="shipping_method.value"
                    :value="shipping_method.value"
                    @change="set_basket_shipping_method"
                />
                &nbsp;
                <label :for="shipping_method.value">
                    {{ shipping_method.display_name }}
                </label>
                <!--only show the date picker if standard shipping is selected -->
                <div
                    class="inline-block"
                    v-if="
                        basket.lines.length > 0 &&
                        shipping_method.value === 'standard' &&
                        shipping_code === 'standard' &&
                        basket.can_change_delivery_date
                    "
                >
                    <div class="inline-picker">
                        (<date-picker
                            :min_date="basket_min_delivery_date"
                            :set_date="set_basket_date"
                            :date="basket_delivery_date"
                            :button_text="gettext('Datum wählen')"
                        ></date-picker
                        >)
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from './delivery_date.vue'
import dateFormat from 'dateformat'
import { parse_date } from '../utils'
export default {
    props: ['basket', 'basket_options'],
    data() {
        return {}
    },
    components: {
        'date-picker': DatePicker,
    },
    methods: {
        set_basket_shipping_method(event) {
            const shipping_code = event.target.value
            const data = { shipping_code }
            this.$store.dispatch('update_basket', { data })
        },
        set_basket_date(date) {
            const requested_delivery_date = dateFormat(date, 'yyyy-mm-dd')
            const data = { requested_delivery_date }
            this.$store.dispatch('update_basket', { data })
        },
    },
    computed: {
        shipping_code() {
            return this.basket.shipping_code
        },
        shipping_method() {
            return this.shipping_methods.find(
                (method) => method.value === this.shipping_code
            )
        },
        shipping_methods() {
            let options = this.basket_options
            if (options.actions === undefined) {
                return []
            }
            return options.actions.PUT.shipping_code.choices
        },
        basket_min_delivery_date() {
            const line = this.$store.getters.basket_regular_lines.reduce(
                function (prev, curr) {
                    return prev.earliest_delivery_date <
                        curr.earliest_delivery_date
                        ? prev
                        : curr
                }
            )
            return parse_date(line.earliest_delivery_date)
        },
        basket_delivery_date() {
            return this.basket_min_delivery_date
        },
        show_shipping() {
            return this.$store.getters.basket_regular_lines.length > 0
        },
    },
}
</script>

<style>
.inline-picker {
    display: inline-block;
    margin-left: 1ch;
}
.inline-picker .input-group .form-control {
    margin-bottom: -10px;
}
</style>
