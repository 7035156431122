<template>
    <div>
        <div v-if="basket.order_total !== undefined">
            <h3>{{ gettext('Gesamtbeträge') }}</h3>
            <table class="table table-condensed section">
                <tbody>
                    <tr>
                        <td>
                            {{ gettext('Versand') }} ({{
                                basket.shipping_method_name
                            }})
                        </td>
                        <td class="align-right">
                            {{
                                currency(
                                    basket.shipping_charge_excl_tax,
                                    basket.currency_locale
                                )
                            }}
                        </td>
                    </tr>

                    <tr
                        v-for="discount in basket.discounts"
                        :key="discount.name"
                    >
                        <td>
                            <div v-if="discount.details.length === 0">
                                {{ discount.description }}
                            </div>
                            <div
                                v-for="item in discount.details"
                                :key="item.description"
                                class="basket-discount"
                            >
                                {{ item.description }}
                            </div>
                        </td>
                        <td class="align-right">
                            <div v-if="discount.details.length === 0">
                                {{
                                    currency(
                                        -1 * discount.discount,
                                        basket.currency_locale
                                    )
                                }}
                            </div>
                            <div
                                v-for="item in discount.details"
                                :key="item.description"
                            >
                                {{
                                    currency(item.value, basket.currency_locale)
                                }}
                            </div>
                        </td>
                    </tr>
                    <tr class="total">
                        <th>{{ gettext('Nettosumme') }}</th>
                        <th class="align-right">
                            {{
                                currency(
                                    basket.order_total.excl_tax,
                                    basket.currency_locale
                                )
                            }}
                        </th>
                    </tr>
                </tbody>
            </table>

            <table class="table table-condensed section">
                <tbody>
                    <tr>
                        <td>
                            {{ gettext('Umsatzsteuer') }}
                            {{ basket.order_total.tax_rate_percentage }}%
                        </td>
                        <td class="align-right">
                            {{
                                currency(
                                    basket.order_total.tax,
                                    basket.currency_locale
                                )
                            }}
                        </td>
                    </tr>
                    <tr class="total">
                        <th>{{ gettext('Summe') }}</th>
                        <th class="align-right price_color">
                            {{
                                currency(
                                    basket.order_total.incl_tax,
                                    basket.currency_locale
                                )
                            }}
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { currency } from '../format'
export default {
    props: ['basket'],
    data() {
        return {}
    },
    methods: {
        currency,
    },
}
</script>
